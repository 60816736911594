import './CSS/SelectionLine.css';
import {useState} from "react";
import {EditableField} from "./EditableField";

function isSelected(e) {
    const cell = e.target.parentNode
    // const value = e.target.value
    const selection_class = "sn_selected"
    // console.log(cell)
    // console.log(value)
    if (e.target.checked) {
        cell.classList.add(selection_class)
    } else {
        cell.classList.remove(selection_class)
    }


}

export function SelectionLine({info, setLine}) {
    const [isEditing, setIsEditing] = useState({});
    const reference_keys = {next_periodic: "Periodica", next_functioning: "Funzionamento", next_integrity: "Integrità", next_internal: "Interna"}
    const list = []
    for (const key in reference_keys) {
        if (info[key]) {
            list.push(generateLine(
                info,
                reference_keys[key],
                (info["day_left"]<=0),
                (info["day_left"]<=30),
                isEditing,
                setIsEditing,
                setLine
            ))
        }
    }
    return list
}


function generateLine(info, type, expired, expiring, isEditing, setIsEditing, setLine) {

    return <div className={"selection_line"} key={info["sn_new"]+type}>
        <span className={"select_row_column"}>
            <input type="checkbox" onClick={isSelected} value={0}/>
        </span>
        <span className={"sn_column " + (expired ? "expired_sn" : (expiring ? "expiring_sn" : ""))}>{info.sn_new}</span>
        <span className={"verify_column"}>{type}</span>
        <EditableField info={info} type={type} fieldName={"pressure"} isEditing={isEditing} setIsEditing={setIsEditing} setLine={setLine}/>
        <EditableField info={info} type={type} fieldName={"volume"} isEditing={isEditing} setIsEditing={setIsEditing} setLine={setLine}/>
        <span className={"type_column"}>
            <EditableField info={info} type={type} fieldName={"system_type"} isEditing={isEditing} setIsEditing={setIsEditing} setLine={setLine}/>
            -
            <EditableField info={info} type={type} fieldName={"system_info"} isEditing={isEditing} setIsEditing={setIsEditing} setLine={setLine}/>
            {/*{info.system_type} - {info.system_info}*/}
        </span>
    </div>
}

