import './CSS/Compiler.css';
import {AutocompleteField} from "./AutocompleteField";
import {useState} from "react";
import {SelectionTable} from "./SelectionTable";

// function getSerialNumber(reports, selectedClient) {
//     return ""
// }

export function NewContract({reports, setReport}) {
    const [info, setInfo] = useState({
        client: {},
        year: new Date().getFullYear(),
        sn: []
    });
    // console.log(reports)
    // const clients = reports.map(oggetto => oggetto);
    const title = "Cliente"
    // const possibleSerialNumber = getSerialNumber(reports, info.client)

    const setClient = (value) => {
        let clone = {...info}
        if (value != null) {
            clone.client = value
        } else {
            console.log("stai provando a cancellare?")
            clone.client = {}
        }
        setInfo(clone);
    }

    const handleChange = (event, field) => {
        let clone = {...info}
        clone[field] = event.target.value
        setInfo(clone);
    };

    return <div className={"mainSection"}>
        <div className={"subtitle_fill_fields"}>
            <h2 className={"title"}>Nuovo contratto</h2>
        </div>
        <div className={"matr_insert"}>
            <span className={"matr_field_title"}>{title}</span>
            <AutocompleteField reports={reports} serialNumber={info.client} setSerialNumber={setClient} type={"client"}/>
        </div>
        {(Object.keys(info.client).length > 0) && <>
            <h3>Seleziona gli impianti</h3>
            <SelectionTable reports={reports} selectedClient={info.client} setReports={setReport}/>
            <div className={"fill_fields"}>
                <span className={"field_line"}>Data della verifica:</span>
                <input className={"short_field field_grid"} type="date" value={info.date} onChange={e => handleChange(e, "date")}/>

                <span className={"field_line"}>Numero verbale:</span>
                <input className={"short_field field_grid"} placeholder={info.client+"_2024"} type="text" value={info.verbale ?? info.client+"_"+new Date().getFullYear()} onChange={e => handleChange(e, "verbale")}/>
                <span className={"field_line"}>Verifica:</span>
                <select value={info.verify_type ?? ""} onChange={e => handleChange(e, "verify_type")}>
                    <option value="pvp">Prima Verifica Periodica</option>
                    <option value="vp">Verifica successiva</option>
                </select>

            </div>
        </>
        }
    </div>
}