import {SelectionLine} from "./SelectionLine";

function orderElements(a, b) {
    // Ordina per system_type
    if (a.system_type < b.system_type) {
        return -1;
    } else if (a.system_type > b.system_type) {
        return 1;
    }

    // Se system_type è uguale, ordina per system_info
    if (a.system_info < b.system_info) {
        return -1;
    } else if (a.system_info > b.system_info) {
        return 1;
    }

    // Se system_type e system_info sono uguali, ordina per sn_new
    if (a.sn_new < b.sn_new) {
        return -1;
    } else if (a.sn_new > b.sn_new) {
        return 1;
    }
}

function getPossibleLines(reports, selectedClient) {
    /**
     * Extract the lines related to this client (and related city)
     * @type {*[]}
     */
    const possibleSn = []
    // Filter reports
    for (const report of reports) {
        if (report.client_name === selectedClient.client_name && report.city === selectedClient.city) {
            possibleSn.push(report);
        }
    }
    console.log("Filtrate "+possibleSn.length+" linee")
    return possibleSn.sort(orderElements)
}

export function SelectionTable({reports, selectedClient, setReports}) {
    console.log("reports")
    console.log(reports)
    function updateLine(value) {
        let clone = [...reports]
        // console.log(reports)
        for (let report of clone) {
            if (report["sn_new"] === value["sn_new"]) {
                // console.log("Nuovo:")
                // console.log(value)
                // console.log("Vecchio:")
                // console.log(report)
                for (const reportKey in value) {
                    if(!(reportKey in report) || report[reportKey] !== value[reportKey]) {
                        report[reportKey] = value[reportKey]
                        console.log("Aggiorno: " + reportKey)
                    }
                }

            }
        }
        for (let report of clone) {
            if (report["sn_new"] === value["sn_new"]) {
                // console.log("Nuovo post:")
                // console.log(report)
            }
        }
        setReports(clone)
        console.log("Updated all reports")
    }

    const possibleSn = getPossibleLines(reports, selectedClient)
    return <div className={"selection_table"}>
        <div className={"selection_line selection_line_title"}>
            <span className={"select_row_column"}>☑️</span>
            <span className={"sn_column"}>Matricola</span>
            <span className={"verify_column"}>Verifica</span>
            <span className={"p_column"}>P</span>
            <span className={"v_column"}>V</span>
            <span className={"type_column"}>Tipo</span>
        </div>
        {possibleSn.map((item) => (
            <SelectionLine info={item} setLine={updateLine}/>
        ))}
    </div>
}
