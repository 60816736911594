import {Component} from "react";
import * as PropTypes from "prop-types";
import {default_value, yearButtons} from "./NewReport";

function create_buttons(field_name, verify_date, selected_date, change_function) {
    const next_buttons = [];
    const button_value = {
        "name": field_name,
        "value": ""
    }
    let selected = ""
    if (field_name.startsWith("next_")) {
        if (!selected_date) {
            selected = " selected"
        }
        next_buttons.push(
            <button
                className={"spb_left "+selected}
                key={"button_no"}
                value={""}
                onClick={e => change_function(e, button_value)}
            >
                {"Non prevista"}
            </button>
        )
        if (yearButtons.hasOwnProperty(field_name)) {
            const buttons_to_create = yearButtons[field_name].length
            for (let i = 0; i < buttons_to_create; i++) {
                const button_info = yearButtons[field_name][i];
                selected = ""
                let class_name = "spb_center"
                if (i+1 === buttons_to_create) class_name = "spb_right";
                if (evaluate_selected_date(verify_date, selected_date, button_info.value)) {
                    selected = " selected"
                    console.log("Field ", field_name, " - Selezionato")
                } else {
                }
                next_buttons.push(
                    <button
                        className={class_name+" "+selected}
                        key={"button_"+i}
                        value={button_info.value}
                        onClick={(e) => {
                            let info = {...button_value}
                            info.value = elaborate_date(verify_date, button_info.value)
                            change_function(e, info)
                            console.log("Ho aggiornato i valori a: ", info)
                        }}
                    >
                        {button_info.text}
                    </button>
                )
            }
        }
    }
    return next_buttons
}


function evaluate_selected_date(ver_date, selected_date, button_value) {
    try {
        const selectedDate = new Date(selected_date);
        const new_date = elaborate_date(ver_date, button_value);
        return selectedDate.toISOString().split('T')[0] === new_date;
    } catch (error) {
        return false;
    }
}

/**
 * Calculate the new date starting from verify date and the value on the button
 * @param ver_date {string} The date of the verify in format yyyy-mm-dd
 * @param button_value {int|string} The value in year of the button. Can be an empty string in case of no use of the date
 * @returns {string} The date of the next_verify based on the button pressed
 */
function elaborate_date(ver_date, button_value) {
    const verDate = new Date(ver_date);
    // Aggiunge 'button_value' anni a verDate
    verDate.setFullYear(verDate.getFullYear() + button_value);
    return verDate.toISOString().split('T')[0];
}

export class FormDate extends Component {
    render() {
        let selectedValue = undefined
        let field_name = this.props.valueFill
        let g_ver = default_value.g_ver
        if (this.props.info) {
            selectedValue = this.props.info[field_name]
            g_ver = this.props.info["g_ver"]
        }

        const next_buttons = create_buttons(field_name, g_ver, selectedValue, this.props.onChange)
        return <div className={"select_date_line"}>
            <span className={"short_field field_line label"}>{this.props.textFill}:</span>
            <input
                className={"short_field field_grid"}
                type="date"
                value={selectedValue}
                onChange={(e) => this.props.onChange(e, field_name)}
            />
            {next_buttons.length > 0 &&
                <><span className={"label"}>{"Pulsanti rapidi:"}</span>
                    <div className={"button_container"}>
                        {next_buttons}
                    </div>
                </>
            }

        </div>;
    }
}

FormDate.propTypes = {
    textFill: PropTypes.string,
    info: PropTypes.shape({serialNumber: PropTypes.shape({}), g_ver: PropTypes.string, anno_matr: PropTypes.number}),
    valueFill: PropTypes.string,
    onChange: PropTypes.func
};