import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// function findDictionary(listaDizionari, stringa, key) {
//     // Filtra la lista per dizionari con il valore della chiave uguale alla stringa
//     const dizionarioTrovato = listaDizionari.filter(dizionario => dizionario[key] === stringa);
//
//     // Restituisce il primo dizionario trovato (o undefined se non trovato)
//     return dizionarioTrovato[0];
// }

function orderElements(elements, primary_key, secondary_key) {
    function compareObj(a, b) {
        //Compare primary key
        if (a[primary_key] < b[primary_key]) {
            return -1;
        } else if (a[primary_key] > b[primary_key]) {
            return 1
        }
        //Compare secondary key
        if (a[secondary_key] < b[secondary_key]) {
            return -1;
        } else if (a[secondary_key] > b[secondary_key]) {
            return 1
        }
    }
    return elements.sort(compareObj)
}

function generateUniqueReports(reports, primary_key, secondary_key) {
    const reducedArray = reports.map(obj => {
        const newObj = {};
        for (const key of [primary_key, secondary_key]) {
            newObj[key] = obj[key];
        }
        return newObj;
    })
    // Crea un Set per rimuovere i duplicati
    const setSenzaDuplicati = new Set(reducedArray.map(JSON.stringify));

    // Converti il Set in un nuovo array di oggetti
    const arraySenzaDuplicati = [...setSenzaDuplicati].map(JSON.parse);

    return orderElements(arraySenzaDuplicati, primary_key, secondary_key);
}

// function normalizeString(str) {
//     /**
//      * Normalize the text removing from search special text, accent marks and case sensitivity
//      */
//     // Rimuovere i caratteri speciali
//     const noSpecialChars = str.replace(/[^a-zA-Z0-9 ]/g, "");
//     // Convertire le lettere accentate
//     const noAccents = noSpecialChars.normalize("NFD").replace(/[\u0300-\u036F]/g, "");
//     // Convertire in minuscolo
//     return noAccents.toLowerCase();
// }

export function AutocompleteField({reports, serialNumber, setSerialNumber, type="sn"}) {
    let info = {
        label: "Matricola",
        main_value: "sn_new",
        secondary_value: "client_name"
    }

    if (type === "client") {
        info = {
            label: "Cliente",
            main_value: "client_name",
            secondary_value: "city"
        }
    }

    const uniqueReports = generateUniqueReports(reports, info.main_value, info.secondary_value)

    return <span className={"matr_field"}>
            <Autocomplete
            id="free-solo-demo"
            value={(Object.keys(serialNumber).length > 0) ? serialNumber : null}
            getOptionLabel={(option) => {
                if(info.main_value in option && info.secondary_value in option) {
                    return option[info.main_value] + " ➡️ " + option[info.secondary_value]
                } else {
                    return ""
                }

            }}
            options={uniqueReports}
            onChange ={(event, newValue) => setSerialNumber(newValue)}
            isOptionEqualToValue={(option, value) => {
                return (option[info.main_value] === value[info.main_value] && option[info.secondary_value] === value[info.secondary_value])}
            }
            renderInput={(params) => (
                <TextField {...params} label={info.label} />)}
            />
        </span>
}