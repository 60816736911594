import './CSS/App.css';
import {Table} from "./Table";
import {TitleBar} from "./TitleBar";
import {useState} from "react";
import {NewReport} from "./NewReport";
import {NewContract} from "./NewContract";

function App() {
    // The page selected
    const [page, setPage] = useState("table");
    // The list of all available reports
    const [reports, setReports] = useState([]);

    return (
        <>
            <TitleBar page={page} setPage={setPage}/>
            <div className="App">
              <header className="App-header">
                  {page === 'table' && <Table reports={reports} setReports={setReports}/>}
                  {page === 'insert' && <NewReport reports={reports}/>}
                  {page === 'contract' && <NewContract reports={reports} setReport={setReports}/>}
              </header>
            </div>
        </>
);
}

export default App;
