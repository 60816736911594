const association = {
    pressure: {
        column: "p_column",
        unit: "bar",
        default: "___"
    },
    volume: {
        column: "v_column",
        unit: "l",
        default: "___"
    },
    system_type: {
        column: "st_column",
        unit: "l",
        default: "N/A"
    },
    system_info: {
        column: "si_column",
        unit: "l",
        default: "N/A"
    },
}

function getSectionType(e) {
    /**
     * Retrieve the type associated to the pressed cell
     */
    let classlist = [...e.currentTarget.classList]
    for (const associationKey in association) {
        if(classlist.includes(association[associationKey]["column"])) {
            return associationKey
        }
    }
    return "na"
}



export function EditableField({info, type, fieldName, isEditing, setIsEditing, setLine}) {

    function getKey(value) {
        /**
         * Create the unique key that identify a cell in the table
         */
        return value + "_" + info["sn_new"] + "_" + type
    }

    function toggleEdit(e) {
        /**
         * Toggle the selected field
         */
        let clone = {...isEditing}
        let section = getSectionType(e)
        if (clone[getKey(section)] != null) {
            // Toggle
            clone[getKey(section)] = !clone[getKey(section)]
        } else {
            // First edit - Enable it
            clone[getKey(section)] = true
        }
        setIsEditing(clone)
    }

    function convertToEditField(e) {
        /**
         * On double click, toggle cell to edit mode
         */
        if (e.detail === 2) {
            toggleEdit(e)
        }
    }

    function handleChange(e) {
        /**
         * Manage the value of the edit field in order to dynamically update the value
         */
        let val = e.target.value
        if (val != null) {
            let section = getSectionType(e)
            let clone = {...info}
            clone[section] = val
            setLine(clone)
            console.log("Updated line")
        } else {
            console.log("Ignoring modify")
        }
    }

    function handleBlur(e) {
        /**
         * On focus lost, toggle cell to visualization mode
         */
        toggleEdit(e)
    }
    return <>
        {(getKey(fieldName) in isEditing && isEditing[getKey(fieldName)]) ? (
            <input className={association[fieldName]["column"]+" editable"} data-unit={association[fieldName]["unit"]} type="text" value={info[fieldName] ?? ""} onChange={handleChange} onBlur={handleBlur} autoFocus/>
        ) : (
            <span className={association[fieldName]["column"]} data-unit={association[fieldName]["unit"]} onClick={convertToEditField}>{info[fieldName] ?? association[fieldName]["default"]}</span>
        )}
    </>
}