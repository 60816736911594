import './CSS/TitleBar.css';

export function TitleBar({page, setPage}) {

    const handleViewChange = (view) => {
        setPage(view);
    };

    return <>
        <div className={'title_div title_bar_div'}>
            <img className={'title_icon'} src={"ancc.png"} alt={''}></img>
            <span className={'main_title'}>SilvioDB</span>
        </div>
        <div className={"title_div title_bar_buttons"}>
            <button className={'title_button'} onClick={() => handleViewChange('table')}>Verifiche</button>
            <button className={'title_button'} onClick={() => handleViewChange('insert')}>Nuovo verbale</button>
            {/*<button className={'title_button'} onClick={() => handleViewChange('contract')}>Nuovo contratto</button>*/}
        </div>
    </>
}